import {
  onUnmounted,
  onDeactivated,
  inject,
  provide,
  watch,
  ref,
  reactive,
} from 'vue'
import { Expose } from '@shein-aidc/basis-expose'
import { onMountedOrActivated } from './onMountedOrActivated'

/**
 * 商品行曝光处理
 * @param ref<HTMLElement> exposeRef - 曝光元素
 */
export const useExpose = (exposeRef) => {
  const isParentExposed = ref(false)
  const exposeTask = reactive({})
  const expose = new Expose({
    observeHide: true,
    exposeRatio: 1,
  })

  /**
   * 提供给组件注册曝光事件
   * @param {String} key 事件名称
   * @param {Function} callback 回调函数
   * @param {Boolean} once 是否只触发一次
   */
  const registerExpose = (key, callback, once = true) => {
    const component = exposeTask[key] || (exposeTask[key] = { exposed: false, once: false, callback: () => ({}) })
    component.callback = callback
    component.once = once

    // 同步注册的曝光事件
    if (isParentExposed.value && typeof callback === 'function' && !component.exposed) {
      callback()
      if (once) component.exposed = true
    }
  }

  onMountedOrActivated(() => {
    expose.observe(
      {
        elements: exposeRef.value,
        once: false,
      },
      ({ exposeDoms }) => {
        const isShow = exposeDoms?.length > 0
        isParentExposed.value = isShow
      },
    )
  })

  onDeactivated(() => {
    isParentExposed.value = false
    Object.entries(exposeTask).forEach(([key]) => {
      exposeTask[key].exposed = false
    })
  })

  watch(isParentExposed, exposed => {
    if (exposed) {
      // 异步注册的曝光事件
      // @ts-ignore
      Object.entries(exposeTask).forEach(([key, { exposed, once, callback }]) => {
        if (!exposed && typeof callback === 'function') {
          callback()
          if (once) exposeTask[key].exposed = true
        }
      })
    }
  })

  onUnmounted(() => {
    expose.destroy()
  })

  return registerExpose
}

export const useProvideRegisterExpose = registerExpose => {
  provide('registerExpose', registerExpose)
}

export const useInjectRegisterExpose = () => {
  return inject('registerExpose', (name, callback, once?) => {
    console.error(
      'useInjectRegisterExpose: registerExpose is not provided!',
      name,
      callback,
      once,
    )
  })
}
