import type { SFSTag } from '../../../../../types/laptop/index.ts'
/**
 * SFSTag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件 props
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }):SFSTag.Props {
  const info = data.value.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'localWarehouse') || null

  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    isShow: !!info,
    isShowMask: (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem,
    text: info?.view?.text || '',
    suffixText: info?.view?.suffixText,
    bgColor: info?.view?.bgColor || '#ECFCF3',
    textColor: info?.view?.textColor || '#3d7e59',
    maxWidth: '100%',
  }
}
