<template>
  <div
    v-if="isShow"
    :style="styles"
    :class="classes"
  >
    <div class="bsc-cart-item-goods-belt__content">
      <ResizeFont
        :is-multiline="true"
        :multiline-num="2"
      >
        <ResizeFontText
          :init-font-size="initFontSize"
          :resize-font-min="8"
          :resize-font-step="1"
        >
          {{ beltText }}
        </ResizeFontText>
      </ResizeFont>
    </div>
  </div>
</template>

<script name="GoodsBelt" setup lang="ts">
import { computed } from 'vue'
import ResizeFont from '../../components/resize-font/resize-font.vue'
import ResizeFontText from '../../components/resize-font/resize-font-text.vue'
import type { GoodsBelt } from '../../../../types/laptop/index.ts'

/**
 * 商品行腰带组件
 */

// props
const props = withDefaults(defineProps<GoodsBelt.Props>(), {
  isShow: false,
  isShowMask: false,
  bgColor: 'rgba(0,0,0.6)',
  fontColor: '#FFFFFF',
  beltText: '',
  size: 'NORMAL',
})

// data
// computed
const styles = computed(() => {
  return {
    '--font-color': props.fontColor,
    '--bg-color': props.bgColor,
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

const classes = computed(() => {
  return {
    'bsc-cart-item-goods-belt': true,
    'bsc-cart-item-goods-belt_normal': props.size === 'NORMAL',
    'bsc-cart-item-goods-belt_mini': props.size === 'SMALL',
    'bsc-cart-item-goods-belt_large': props.size === 'LARGE',
  }
})
const initFontSize = computed(() => {
  return (props.size === 'LARGE' || props.size === 'NORMAL') ? 12 : 10
})
</script>

<style lang="less">
.bsc-cart-item-goods-belt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  color: var(--font-color);
  padding: 4px;
  width: 100%;
  opacity: var(--mask-opacity);
  &_large {
    min-height: 28px;
    font-size: 12px;
    font-weight: 700;
  }
  &_normal {
    min-height: 20px;
    font-size: 12px;
    font-weight: 700;
  }
  &_mini {
    min-height: 17px;
    font-size: 10px;
  }
  &__content {
    overflow: hidden;
    line-height: 1;
  }
}
</style>
