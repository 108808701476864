<template>
  <span
    v-if="isShow"
    class="goods-delete-button-v2"
    role="button"
    :title="title"
    @click="handleClick"
  >
    <sui_icon_delete_16px_1 size="16px" />
  </span>
</template>

<script name="GoodsDeleteButtonV2" setup lang="ts">
import sui_icon_delete_16px_1 from '../../components/icon/sui_icon_delete_16px_1.vue'
import type { GoodsDeleteButton } from '../../../../types/laptop/index.ts'

/**
 * 商品删除组件V2
 */

// events
const emit = defineEmits(['click', 'report'])

// props
withDefaults(defineProps<GoodsDeleteButton.Props>(), {
  title: '',
  isShow: true,
})

const handleClick = () => {
  emit('report')
  emit('click')
}
</script>

<style lang="less">
.goods-delete-button-v2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  color: @sui_color_gray_dark1;
  text-decoration: none;
  font-size: 13px;
  &:hover {
    background: #f6f6f6;
  }
}
</style>
