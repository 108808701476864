import type { GoodsSeriesFlag } from '../../../../types/laptop/index.ts'

/**
 * GoodsSeriesFlag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsSeriesFlag.Props {
  return {
    isShow: !!data.value.product?.series_badge && !!data.value.product?.series_badge?.image_url,
    imgUrl: data.value.product?.series_badge?.image_url ?? '',
    isLazyLoad: true,
  }
}
