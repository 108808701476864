<template>
  <div
    v-if="isShow"
    :style="styles"
    :class="classes"
    @click="handleClick"
  >
    {{ text }}
  </div>
</template>

<script name="FindSimilarText" setup lang="ts">
import { computed, watch } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import type { FindSimilarText } from '../../../../types/laptop/index.ts'

/**
 * 商品行找同款文案组件
 */

// hooks
const registerExpose = useInjectRegisterExpose()
// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = withDefaults(defineProps<FindSimilarText.Props>(), {
  isShow: false,
  isShowMask: false,
  isClick: false,
  text: '',
  type: 'rounded',
})

// data
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
const classes = computed(() => {
  return {
    'bsc-cart-item-find-similar-text': true,
    'bsc-cart-item-find-similar-text_disabled': !props.isClick,
    [`bsc-cart-item-find-similar-text_${props.type}`]: true,
  }
})

// events
const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}

/**
 * 组件曝光
 */
 const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('FindSimilarText', callback)
    }
  }, {
    immediate: true,
  })
}

handleExpose()
</script>

<style lang="less">
.bsc-cart-item-find-similar-text {
  display: inline-block;
  padding: 4.5px 10px;
  line-height: 1;
  color: @sui_color_gray_dark1;
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
  opacity: var(--mask-opacity);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  &_rounded {
    border-radius: 14px;
    border: 1px solid #F2F2F2;
    background: #F2F2F2;
    &:hover:not(.bsc-cart-item-find-similar-text_disabled) {
      border: 1px solid #222222;
    }
  }
  &_square {
    background: #F6F6F6;
    &:hover:not(.bsc-cart-item-find-similar-text_disabled) {
      opacity: 0.6;
    }
  }
  &_disabled {
    opacity: .3;
    cursor: default;
    cursor: not-allowed
  }
}
</style>
