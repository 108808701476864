import type { GoodsStore } from '../../../../types/laptop/index.ts'

/**
 * GoodsStore 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsStore.Props {
  // 是否新人专享商品
  const isNewUserGoods = data.value.aggregateProductBusiness?.showNewUsersBonus == '1'
  // 是否赠品/加价购附属品
  const isAdditionGoods = (data.value.aggregateProductBusiness?.isAdditionGoods == 1 || data.value?.isPayMemberGift === '1') && data.value.is_checked == '0'
  // 是否失效商品
  const isInvalidGoods = data.value.isInvalid == '1'

  return {
    isShow: data.value.isShowStoreTitle === '1',
    isShowMask: isNewUserGoods || isAdditionGoods || isInvalidGoods,
    isClick: !!data.value.storeRouting,
    storeTitle: data.value.store_title,
    storeLogo: data.value.store_logo,
    storeTrendLogo: data.value.store_trend_logo || '',
    storeIconSize: '16px',
    textFontSize: '13px',
    textFontWeight: 'normal',
    maxWidth: '100%',
  }
}
