<template>
  <SPopover
    v-if="isShow"
    v-model="show"
    placemen="bottom"
    :title="title"
    :show-footer="true"
    trigger="unset"
    @button-click="handleButtonClick"
  >
    <template #buttonYes>
      {{ buttonYes }}
    </template>
    <template #buttonNo>
      {{ buttonNo }}
    </template>
    <template #reference>
      <span
        ref="root"
        class="goods-delete-button-v1"
        role="button"
        @click="handleClick"
      >
        <sui_icon_delete_16px_1 size="16px" />
      </span>
    </template>
  </SPopover>
</template>

<script name="GoodsDeleteButtonV1" setup lang="ts">
import { ref, computed } from 'vue'
import { SPopover } from '@shein-aidc/sui-popover/laptop'
import { useScrollParent } from '../../hooks/useScrollParent'
import { useEventListener } from '../../hooks/useEventListener'
import sui_icon_delete_16px_1 from '../../components/icon/sui_icon_delete_16px_1.vue'
import type { GoodsDeleteButton } from '../../../../types/laptop/index.ts'

/**
 * 商品删除组件V1
 */

// events
const emit = defineEmits(['click', 'report', 'button-click', 'button-report'])

// props
const props = withDefaults(defineProps<GoodsDeleteButton.Props>(), {
  isShow: true,
  title: '',
  buttonYes: '',
  buttonNo: '',
})

// data
const show = ref(false)
const root = ref<HTMLElement>()
const scrollParent = useScrollParent(root)
const scroller = computed(() => props.scroller || scrollParent.value)

const handleClick = () => {
  if (show.value) return
  show.value = true
  emit('report')
  emit('click')
}
const handleButtonClick = (e) => {
  show.value = false
  emit('button-report', e)
  emit('button-click', e)
}

useEventListener('scroll', () => {
  show.value = false
}, {
  target: scroller,
  passive: true,
})
</script>

<style lang="less">
.goods-delete-button-v1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  color: @sui_color_gray_dark1;
  text-decoration: none;
  font-size: 13px;
  &:hover {
    background: #f6f6f6;
  }
}
</style>
