<template>
  <template
    v-if="isShow"
  >
    <DiscountDetails
      v-if="isHover"
      v-bind="discountDetailsProps"
      class="bsc-cart-item-estimated-price__wrapper"
      @opened="onOpened"
      @closed="onClosed"
    >
      <template #reference="{ visible }">
        <EstimatedReference
          v-bind="estimatedReferenceProps"
          :visible="visible"
        />
      </template>
    </DiscountDetails>
    <EstimatedReference
      v-else
      v-bind="estimatedReferenceProps"
    />
  </template>
</template>

<script name="EstimatedPrice" setup lang="ts">
import { computed, watch, ref } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import DiscountDetails from '../../components/discount-details/index.vue'
import EstimatedReference from './estimated-reference.vue'

import type { EstimatedPrice } from '../../../../types/laptop/index.ts'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['expose', 'opened', 'closed', 'exposeChange'])

const props = withDefaults(defineProps<EstimatedPrice.Props>(), {
  isShow: false,
  isShowMask: false,
  isHover: false,
  text: '',
  timestamp: 0,
  hideCountdown: false,
  estimatedPricePopUp: null,
  textColor: '#FA6338',
  lineColor: '#FDB9A6',
  bgColor: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
  maxWidth: '100%',
})

const estimatedReferenceProps = computed(() => ({
  isShowMask: props.isShowMask,
  isHover: props.isHover,
  text: props.text,
  timestamp: props.timestamp,
  hideCountdown: props.hideCountdown,
  textColor: props.textColor,
  lineColor: props.lineColor,
  bgColor: props.bgColor,
  maxWidth: props.maxWidth,
}))

const discountDetailsProps = computed(() => ({
  estimatedPricePopUp: props.estimatedPricePopUp,
}))
const isReport = ref(false)
const isChange = computed(() => ({
  isShow: props.isShow,
  isChecked: props.isChecked,
  estimatedPrice: props.estimatedPrice,
}))

const onOpened = () => {
  emit('opened')
}
const onClosed = () => {
  emit('closed')
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('EstimatedPrice', callback)
    }
  }, {
    immediate: true,
  })
}
const handleChangeExpose = () => {
  watch(() => isChange.value, (n, o) => {
    if (n.isChecked !== o?.isChecked) {
      isReport.value = false
    }
    if (n.isShow) {
      const callback = () => {
        if (n.isShow && !isReport.value) {
          emit('exposeChange', { n, o })
          isReport.value = true
        }
      }
      registerExpose('EstimatedPriceChange', callback, false)
    }
  }, {
    immediate: true,
  })
}
handleChangeExpose()
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-estimated-price__wrapper {
  max-width: 100%;
  > div {
    max-width: 100%;
  }
}
</style>
