<template>
  <swiper-container
    v-if="isShow"
    ref="behaviorSwiper"
    :style="styles"
    init="false"
    class="bsc-cart-item-behavior-label"
  >
    <swiper-slide
      v-for="(item, index) in labels"
      :key="index"
      :index="index"
    >
      <div
        v-show="index === 0 || isStart"
        ref="behaviorSwiperContent"
        class="bsc-cart-item-behavior-label__content"
      >
        <img
          :src="item.icon"
          width="13"
          height="13"
        />
        <span class="label-text">
          {{ item.tagName }}
        </span>
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script name="BehaviorLabel" setup lang="ts">
import { computed, ref, onBeforeUnmount, onMounted, watch } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import { Autoplay } from 'swiper/modules'
import { register } from 'swiper/element'
import { isInViewPort } from '../../utils/isInViewPort'
import { onMountedOrActivated } from '../../hooks/onMountedOrActivated'
import type { BehaviorLabel } from '../../../../types/laptop/index.ts'
import type { Swiper } from 'swiper'
import type { Ref } from 'vue'

typeof window !== 'undefined' && register()

/**
 * 商品行用户行为标签
 */

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['expose', 'labelExpose'])

// props
const props = withDefaults(defineProps<BehaviorLabel.Props>(), {
  isShow: false,
  isShowMask: false,
  labels: () => ([]),
  isSwiper: false,
})

// data
const swiper: Ref<Swiper | null> = ref(null)
const behaviorSwiper:Ref = ref(null)
const behaviorSwiperContent:Ref = ref(null)
const observer:Ref = ref(null)
const isStart:Ref = ref(false)
const isStop:Ref = ref(false)
const exposeLabel:Ref = ref([])

// computed
const styles = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
}))

// wather
watch(() => props.isPause, (value) => {
  if (value) {
    stopSwiper()
  } else {
    startSwiper()
  }
})

// methods

// 用户行为标签曝光
const handleLabelExpose = index => {
  const isInView = isInViewPort(behaviorSwiperContent.value?.[index])
  if (!isInView || !props.isSwiper) return
  const item = props.labels[index]
  if (item && !exposeLabel.value.includes(item.tagId)) {
    exposeLabel.value.push(item.tagId)
    // emit('labelExpose', item)
  }
}

// 开始轮播
const startSwiper = () => {
  if (!swiper.value) return
  swiper.value?.autoplay?.start?.()
  isStop.value = false
}

// 停止轮播
const stopSwiper = () => {
  if (!swiper.value) return
  swiper.value?.autoplay?.stop?.()
  isStop.value = true
}

// 销毁
const destorySwiper = () => {
  if (!swiper.value) return
  swiper.value?.destroy?.()
}

const disconnectObserver = () => {
  if (!observer.value) return
  observer.value?.disconnect?.()
}

// 初始化observer
const initOnObsever = () => {
  observer.value = new IntersectionObserver(
    entries => {
      if (entries[0].intersectionRatio > 0) {
        if (isStart.value) {
          props.isSwiper && startSwiper()
        } else {
          initSwiper()
        }
      } else if (props.isSwiper && isStart.value) {
        stopSwiper()
      }
    },
    {
      threshold: [0, 1],
    },
  )
  observer.value.observe(behaviorSwiper.value)
}

// 初始化swiper
const initSwiper = () => {
  const config = {
    modules: props.isSwiper ? [Autoplay] : [],
    direction: 'vertical',
    allowTouchMove: false,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    loop: true,
    observer: true,
    observeParents: true,
    on: {
      init: swiper => {
        const { realIndex } = swiper
        handleLabelExpose(realIndex)
        isStart.value = true
      },
      realIndexChange: swiper => {
        const { realIndex } = swiper
        handleLabelExpose(realIndex)
      },
    },
  }
  Object.assign(behaviorSwiper.value, config)
  behaviorSwiper.value?.initialize?.()
  swiper.value = behaviorSwiper.value?.swiper
}

// 初始化
const initLabel = () => {
  onMounted(() => {
    if (props.isShow) {
      initOnObsever()
    }
  })
}

// 销毁
const destoryLabel = () => {
  onBeforeUnmount(() => {
    destorySwiper()
    disconnectObserver()
  })
}

// 用户行为组件曝光
const handleExpose = () => {
  onMountedOrActivated(() => {
    if (props.isShow) {
      const callback = () => {
        emit('expose', props.labels)
      }
      registerExpose('BehaviorLabel', callback)
      window.addEventListener('beforeunload', () => {
        if(props.isSwiper && props.labels.length > 0) {
          emit('labelExpose', { exposeLabel: exposeLabel.value})
        }
      })
    }
  })
}

handleExpose()
initLabel()
destoryLabel()
</script>

<style lang="less">
.bsc-cart-item-behavior-label {
  margin: 0;
  height: 18px;
  overflow: hidden;
  display: block;
  opacity: var(--mask-opacity);

  &__content {
    overflow: hidden;
    font-size: 12px;
    color: @sui_color_micro_emphasis;
    display: inline-flex;
    align-items: center;
    height: 100%;
    max-width: 100%;
    .label-text {
      margin-left: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* rtl:begin:ignore */
      direction: ltr;
    }
    &_visible {
      display: none;
    }
  }
}
</style>
