<template>
  <div
    :style="styles"
    :class="classes"
    @click="handleClick"
  >
    <div class="bsc-cart-item-goods-title__slot-left">
      <slot name="left"></slot>
    </div>
    <a
      :title="title"
      class="bsc-cart-item-goods-title__content"
      v-html="title"
    >
    </a>
  </div>
</template>

<script name="GoodsTitle" setup lang="ts">
import { computed } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import { onMountedOrActivated } from '../../hooks/onMountedOrActivated'
import type { GoodsTitle } from '../../../../types/laptop/index.ts'

/**
 * 商品行标题组件
 */

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = withDefaults(defineProps<GoodsTitle.Props>(), {
  title: '',
  isShowMask: false,
  fontColor: '#000',
  fontSize: '14px',
  lineHeight: '16px',
  hoverStyle: 'BOLD',
  isClick: true,
})

// data
// computed
const styles = computed(() => {
  return {
    '--font-color': props.fontColor,
    '--font-size': props.fontSize,
    '--line-height': props.lineHeight,
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

const classes = computed(() => {
  return {
    'bsc-cart-item-goods-title__wrap': true,
    'bsc-cart-item-goods-title_isLine': props.isClick && props.hoverStyle === 'LINE',
    'bsc-cart-item-goods-title_isBold': props.isClick && props.hoverStyle === 'BOLD',
    'bsc-cart-item-goods-title_isClick': props.isClick,
  }
})

// events
const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('GoodsTitle', callback)
}

onMountedOrActivated(handleExpose)
</script>

<style lang="less">
.bsc-cart-item-goods-title {
  &__wrap {
    display: flex;
    align-items: center;
    opacity: var(--mask-opacity);
  }
  &_isClick {
    .bsc-cart-item-goods-title__content {
      cursor: pointer !important;
    }
  }
  &_isLine:hover {
    .bsc-cart-item-goods-title__content {
      text-decoration: underline !important;
      color: var(--font-color);
    }
  }
  &_isBold:hover {
    .bsc-cart-item-goods-title__content {
      font-weight: bold;
    }
  }
  &__slot-left {
    flex-shrink: 0;
  }
  &__content {
    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--font-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
      color: var(--font-color);
      font-weight: normal;
    }
  }
}
</style>
