<template>
  <div
    v-if="isShow"
    :class="[
      'bsc-cart-item-goods-sale-attr',
      {
        'bsc-cart-item-goods-sale-attr_mask': isShowMask,
        'bsc-cart-item-goods-sale-attr_click': isClick,
        'bsc-cart-item-goods-sale-attr_capsule': type === 'capsule',
      }
    ]"
    :style="styles"
    @click="handleClick"
  >
    <BallLoading v-if="loading" />
    <template v-else>
      <div
        v-if="mode === 'attr' && (colorImage || goodsAttr)"
        class="bsc-cart-item-goods-sale-attr__content"
      >
        <img
          v-if="colorImage"
          :src="colorImage"
          alt=""
          class="bsc-cart-item-goods-sale-attr__img"
        />
        <span
          v-if="goodsAttr"
          class="bsc-cart-item-goods-sale-attr__text"
        >{{ goodsAttr }}</span>
      </div>
      <span v-if="mode === 'reselect'">{{ reselectText }}</span>
      <sui_icon_more_down_12px_1
        v-if="!hideArrow && type === 'capsule'"
        class="bsc-cart-item-goods-sale-attr__icon"
        size="12px"
      />
    </template>
  </div>
</template>

<script name="GoodsSaleAttr" setup lang="ts">
import { computed } from 'vue'
import BallLoading from '../../components/ball-loading.vue'
import sui_icon_more_down_12px_1 from '../../components/icon/sui_icon_more_down_12px_1.vue'
import type { GoodsSaleAttr } from '../../../../types/laptop/index.ts'

/**
 * 商品销售属性组件
 */

// events
const emit = defineEmits(['click', 'report'])

// props
const props = withDefaults(defineProps<GoodsSaleAttr.Props>(), {
  type: 'capsule',
  isShow: false,
  mode: 'attr',
  reselectText: '',
  loading: false,
  isShowMask: false,
  isClick: true,
  hideArrow: false,
  color: '#666',
  size: '13px',
  fontWeight: 'normal',
  maxWidth: '100%',
  height: '24px',
  padding: '0 10px',
  colorImage: '',
  goodsAttr: '',
})

// computed
const styles = computed(() => {
  return {
    '--color': props.color,
    '--size': props.size,
    '--font-weight': props.fontWeight,
    '--max-width': props.maxWidth,
    '--height': props.height,
    '--padding': props.padding,
  }
})

// events
const handleClick = () => {
  emit('report', { event: props.mode, isCustomGoods: props.isCustomGoods })
  if (props.isClick) {
    emit('click', { event: props.mode, isCustomGoods: props.isCustomGoods })
  }
}
</script>

<style lang="less">
.bsc-cart-item-goods-sale-attr {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  min-width: 0;
  max-width: var(--max-width);
  font-size: var(--size);
  color: var(--color);
  font-weight: var(--font-weight);
  height: var(--height);
  line-height: var(--height);
  cursor: default;
  &.bsc-cart-item-goods-sale-attr_mask {
    opacity: .3;
  }
  &.bsc-cart-item-goods-sale-attr_click {
    cursor: pointer;
    &:hover {
      border-color: #222;
    }
  }
  &.bsc-cart-item-goods-sale-attr_capsule {
    padding: var(--padding);
    border-radius: var(--height);
    background-color: #F2F2F2;
    border: 1px solid #F2F2F2;
  }
  .bsc-cart-item-goods-sale-attr__content {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .bsc-cart-item-goods-sale-attr__icon {
    margin-left: 4px;
  }
  .bsc-cart-item-goods-sale-attr__img {
    box-sizing: border-box;
    margin-right: 4px;
    width: 12px;
    height: 12px;
    border: 0.5px solid rgba(0, 0, 0, 0.10);
    border-radius: 100%;
  }
  .bsc-cart-item-goods-sale-attr__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
