<template>
  <div
    ref="tempRef"
    class="bsc-cart-item-mini__wrap"
  >
    <!-- 商品行内容 -->
    <div class="bsc-cart-item-mini__content">
      <!-- 主图、标签、腰带 -->
      <div class="bsc-cart-item-mini__left">
        <GoodsImg
          v-if="goodsImgData.isShow"
          v-bind="goodsImgData.attrs"
        >
          <template #left>
            <GoodsDiscountFlag
              v-if="goodsDiscountFlagData.isShow"
              v-bind="goodsDiscountFlagData.attrs"
            />
          </template>
          <template #right>
            <GoodsSeriesFlag
              v-if="goodsSeriesFlagData.isShow"
              v-bind="goodsSeriesFlagData.attrs"
            />
          </template>
          <template #bottom>
            <GoodsBelt
              v-if="goodsBeltData.isShow"
              v-bind="goodsBeltData.attrs"
            />
          </template>
        </GoodsImg>
      </div>
      <div class="bsc-cart-item-mini__right">
        <!-- 标题 -->
        <div class="bsc-cart-item-mini__right-top">
          <GoodsTitle
            v-if="goodsTitleData.isShow"
            v-bind="goodsTitleData.attrs"
          >
            <template #left>
              <GoodsTitleIcon
                v-if="goodsTitleIconData.isShow"
                v-bind="goodsTitleIconData.attrs"
              />
            </template>
          </GoodsTitle>
          <GoodsStore
            v-if="goodsStoreData.isShow"
            class="bsc-cart-item-mini__store"
            v-bind="goodsStoreData.attrs"
          />
        </div>
        <div class="bsc-cart-item-mini__right-middle">
          <!-- 尺码、商品数量 -->
          <div class="bsc-cart-item-mini__info">
            <GoodsSaleAttr
              v-if="goodsSaleAttrData.isShow"
              class="bsc-cart-item-mini__sale-attr"
              v-bind="goodsSaleAttrData.attrs"
            />
            <GoodsQty
              v-if="goodsQtyData.isShow && goodsQtyData.position === 'up'"
              class="bsc-cart-item-mini__goods-qty"
              v-bind="goodsQtyData.attrs"
            />
          </div>
          <!-- 加车组件 -->
          <AddMore
            v-if="addMoreData.isShow"
            class="bsc-cart-item-mini__add-more"
            v-bind="addMoreData.attrs"
          />
          <!-- 用户行为标签 -->
          <ClientOnly>
            <BehaviorLabel
              v-if="behaviorLabelData.isShow"
              class="bsc-cart-item-mini__behavior-label"
              v-bind="behaviorLabelData.attrs"
            />
          </ClientOnly>
          <!-- 活动标签 -->
          <div class="bsc-cart-item-mini__promotion">
            <SFSTag
              v-if="sfsTagData.isShow"
              class="bsc-cart-item-mini__promotion-item"
              v-bind="sfsTagData.attrs"
            />
            <QuickShipTag
              v-if="quickShipTagData.isShow"
              class="bsc-cart-item-mini__promotion-item"
              v-bind="quickShipTagData.attrs"
            />
            <FreeShippingTag
              v-if="freeShippingTagData.isShow"
              class="bsc-cart-item-mini__promotion-item"
              v-bind="freeShippingTagData.attrs"
            />
            <EvoluTag
              v-if="evoluTagData.isShow"
              class="bsc-cart-item-mini__promotion-item"
              v-bind="evoluTagData.attrs"
            />
            <VoucherTag
              v-if="voucherTagData.isShow"
              class="bsc-cart-item-mini__promotion-item"
              v-bind="voucherTagData.attrs"
            />
            <PromotionTag
              v-if="promotionTagData.isShow"
              class="bsc-cart-item-mini__promotion-item"
              v-bind="promotionTagData.attrs"
            />
          </div>
          <!-- 提示信息 -->
          <div class="bsc-cart-item-mini__tips">
            <ReducePriceHint
              v-if="reducePriceHintData.isShow"
              class="bsc-cart-item-mini__reduce"
              v-bind="reducePriceHintData.attrs"
            />
            <GoodsAlert
              v-if="goodsAlertData.isShow"
              class="bsc-cart-item-mini__alert"
              v-bind="goodsAlertData.attrs"
            />
          </div>
        </div>
        <div class="bsc-cart-item-mini__right-bottom">
          <!-- 价格、数量、删除 -->
          <div class="bsc-cart-item-mini__right-bottom-above">
            <div class="bsc-cart-item-mini__price">
              <GoodsPriceV2
                v-if="goodsPriceData.isShow"
                v-bind="goodsPriceData.attrs"
              >
                <template #price-after>
                  <div class="bsc-cart-item-mini__discount-normal-tag">
                    <DiscountNormalTag
                      v-if="discountNormalTagData.isShow"
                      v-bind="discountNormalTagData.attrs"
                    />
                  </div>
                </template>
                <template #top-right>
                  <div class="bsc-cart-item-mini__operation">
                    <GoodsQty
                      v-if="goodsQtyData.isShow && goodsQtyData.position !== 'up'"
                      class="bsc-cart-item-mini__goods-qty"
                      v-bind="goodsQtyData.attrs"
                    />
                    <GoodsDeleteButtonV2
                      v-if="goodsDeleteButtonData.isShow"
                      class="bsc-cart-item-mini__delete"
                      v-bind="goodsDeleteButtonData.attrs"
                    />
                  </div>
                </template>
              </GoodsPriceV2>
            </div>
          </div>
          <!-- 到手价、降价利诱、售罄利诱 -->
          <div class="bsc-cart-item-mini__right-bottom-below">
            <DiscountLimitTag
              v-if="discountLimitTagData.isShow"
              v-bind="discountLimitTagData.attrs"
            />
            <EstimatedPrice
              v-if="estimatedPriceData.isShow"
              v-bind="estimatedPriceData.attrs"
            />
            <IncentiveReducePrice
              v-if="incentiveReducePriceData.isShow"
              class="bsc-cart-item-mini__incentive-item"
              v-bind="incentiveReducePriceData.attrs"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 放置一些通栏的信息 -->
    <div class="bsc-cart-item-mini__other">
      <!-- 负向信息 -->
      <GoodsNegativeInfo
        v-if="goodsNegativeInfoData.isShow"
        class="bsc-cart-item-mini__negative"
        v-bind="goodsNegativeInfoData.attrs"
      />
    </div>
  </div>
</template>

<script name="BMiniCartItem" setup lang="ts">
import type { Laptop } from '../../../types/laptop'
import { onMountedOrActivated } from '../hooks/onMountedOrActivated'

import { ClientOnly } from '@sheinfe/vue-client-only'

// cell components
import { GoodsImg, useGoodsImg } from '../cell/goods-img'
import { GoodsTitle, useGoodsTitle } from '../cell/goods-title'
import { GoodsStore, useGoodsStore } from '../cell/goods-store'
import { GoodsSeriesFlag, useGoodsSeriesFlag } from '../cell/goods-series-flag'
import { GoodsDeleteButtonV2, useGoodsDeleteButton } from '../cell/goods-delete-button'
import { GoodsDiscountFlag, useGoodsDiscountFlag } from '../cell/goods-discount-flag'
import { GoodsSaleAttr, useGoodsSaleAttr } from '../cell/goods-sale-attr'
import { GoodsAlert, useGoodsAlert } from '../cell/goods-alert'
import { BehaviorLabel, useBehaviorLabel } from '../cell/behavior-label'
import { QuickShipTag, useQuickShipTag } from '../cell/goods-tags/quick-ship-tag'
import { FreeShippingTag, useFreeShippingTag } from '../cell/goods-tags/free-shipping-tag'
import { EvoluTag, useEvoluTag } from '../cell/goods-tags/evolu-tag'
import { VoucherTag, useVoucherTag } from '../cell/goods-tags/voucher-tag'
import { PromotionTag, usePromotionTag } from '../cell/goods-tags/promotion-tag'
import { ReducePriceHint, useReducePriceHint } from '../cell/reduce-price-hint'
import { GoodsQty, useGoodsGoodsQty } from '../cell/goods-qty'
import { GoodsBelt, useGoodsBelt } from '../cell/goods-belt'
import { GoodsPriceV2, useGoodsPrice } from '../cell/goods-price'
import { EstimatedPrice, useEstimatedPrice } from '../cell/estimated-price'
import { DiscountNormalTag, useDiscountNormalTag } from '../cell/discount-normal-tag'
import { DiscountLimitTag, useDiscountLimitTag } from '../cell/discount-limit-tag'
import { GoodsTitleIcon, useGoodsTitleIcon } from '../cell/goods-title-icon'
import { AddMore, useAddMore } from '../cell/add-more'
import { IncentiveReducePrice, useIncentiveReducePrice } from '../cell/incentive-reduce-price'
import { GoodsNegativeInfo, useGoodsNegativeInfo } from '../cell/goods-negative-info'
import { SFSTag, useSFSTag } from '../cell/goods-tags/sfs-tag'

/**
 * 迷你车商品行模板
 */

// template hooks
import { useTemplate } from '../hooks/useTemplate'

// emit
const emit = defineEmits(['expose'])

// template props
// template props
const props = defineProps<{
  data?: Laptop.Data
  config?: Laptop.Config<Laptop.MiniCart>
  language: Laptop.Language<Laptop.MiniCart>,
  constant: Laptop.Constant<Laptop.MiniCart>,
  use: Laptop.MiniCart[],
}>()

// hooks
const {
  // ref
  tempRef,

  // helpers data
  helpersData: [
    goodsImgData,
    goodsTitleData,
    goodsStoreData,
    goodsSeriesFlagData,
    goodsDeleteButtonData,
    goodsDiscountFlagData,
    goodsSaleAttrData,
    goodsAlertData,
    behaviorLabelData,
    quickShipTagData,
    freeShippingTagData,
    evoluTagData,
    voucherTagData,
    promotionTagData,
    reducePriceHintData,
    goodsQtyData,
    goodsBeltData,
    estimatedPriceData,
    discountNormalTagData,
    discountLimitTagData,
    goodsPriceData,
    goodsTitleIconData,
    addMoreData,
    incentiveReducePriceData,
    goodsNegativeInfoData,
    sfsTagData,
  ],

  // methods
  registerExpose,
} = useTemplate(
  props,
  // Helper functions used by the current template
  [
    useGoodsImg,
    useGoodsTitle,
    useGoodsStore,
    useGoodsSeriesFlag,
    useGoodsDeleteButton,
    useGoodsDiscountFlag,
    useGoodsSaleAttr,
    useGoodsAlert,
    useBehaviorLabel,
    useQuickShipTag,
    useFreeShippingTag,
    useEvoluTag,
    useVoucherTag,
    usePromotionTag,
    useReducePriceHint,
    useGoodsGoodsQty,
    useGoodsBelt,
    useEstimatedPrice,
    useDiscountNormalTag,
    useDiscountLimitTag,
    useGoodsPrice,
    useGoodsTitleIcon,
    useAddMore,
    useIncentiveReducePrice,
    useGoodsNegativeInfo,
    useSFSTag,
  ],
)

// methods
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('MiniCartItem', callback)
}

onMountedOrActivated(handleExpose)
</script>

<style lang="less">
// 模板左侧高度
@template-left-width: 90px;
// 模板左侧高度
@template-left-height: 90px;
// 模板左右间距
@template-gap: 10px;
// 模板右侧元素上下间距
@template-right-gap: 6px;

.bsc-cart-item-mini {
  &__wrap {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    text-align: left;
    gap: 4px;
    line-height: 1.15;
  }
  &__content {
    display: flex;
  }
  &__left {
    margin-right: var(--template-gap, @template-gap);
    width: var(--template-left-width, @template-left-width);
    height: var(--template-left-height, @template-left-height);
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: var(--template-left-height, @template-left-height);
    width: 0;
    gap: @template-right-gap;
  }
  &__right-middle {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
    gap: @template-right-gap;
  }
  &__right-top {
    display: flex;
    flex-direction: column;
  }
  &__right-bottom {
    display: flex;
    flex-direction: column;
  }
  &__right-bottom-above {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__right-bottom-below {
    display: flex;
    align-items: center;
  }
  &__other {
    &:empty {
      margin-top: -4px;
    }
  }
  &__store {
    margin-top: 6px;
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:empty {
      margin-top: -@template-right-gap;
    }
  }
  &__add-more {
    width: fit-content
  }
  &__promotion {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    &:empty {
      margin-top: -@template-right-gap;
    }
  }
  &__tips {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: @template-right-gap;
    &:empty {
      margin-top: -@template-right-gap;
    }
  }
  &__sale-attr {
    padding-right: 6px;
  }
  &__goods-qty {
    flex-shrink: 0;
  }
  &__price {
    flex: 1;
    min-width: 0;
  }
  &__operation {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    > :nth-child(1) {
      margin-left: 6px;
    }
  }
  &__discount-normal-tag {
    display: inline-flex;
    margin: 1.5px 0 0 4px;
    max-width: 100%;
    transform: translateY(-1.5px);
  }
  &__negative {
    margin-left: calc(var(--template-left-width, @template-left-width) + @template-gap);
  }
}
</style>
